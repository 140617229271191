import { AxiosRequestConfig } from 'axios';

import { IActiveLoan, IMyLoan, ILoanRequest, ILoanRequestDetail, ILoanStatistics, TActiveLoans, ILoanCollection, TLoanSortParams } from '@/redux/loans/model';
import { SIMPLEHASH_CHAIN } from 'constant';
import { getSimpleHash } from 'simplehash_api/walletApi';
import { testTokenAddress } from 'smart-contracts/addresses';
import { AddressType } from 'types';

import { IAcceptLoanRequestPayload, ICollectionLoanDetails, ICollectionSearch, ICollectionSimplehashDetails, ILoanRequestPayload, ILoanTermBaseFields, IPaybackLoanPayload, IPayloadUpdateLoanMember, IResponseCollection, IResponseNFT, ISignatureData } from './model';
import { deleteRequest, getRequest, postQuicknode, postRequest, putRequest } from '../api';

export const postEscrow = async (signatureData: ISignatureData): Promise<string> => {
  const res = await postRequest('/contracts/escrow', signatureData, undefined, false, true);
  return res.signature;
};

const postRequestBackend = (url: string, body: any, config?: AxiosRequestConfig) => {
  return postRequest(url, body, config, false, true);
};

const getRequestBackend = (url: string, config?: AxiosRequestConfig) => {
  return getRequest(url, config, false, true);
};

export const makeLoanOffer = async (loanOfferPayload: ILoanRequestPayload) => {
  return postRequestBackend('/loan/request', loanOfferPayload);
};

export const getNFTsInWallet = (walletAddress: AddressType, next: string | null, contract_ids: string, order_by?: string): Promise<IResponseNFT> => {
  return getSimpleHash('owners_v2', {
    chains: SIMPLEHASH_CHAIN,
    wallet_addresses: walletAddress,
    contract_ids: `${SIMPLEHASH_CHAIN}.${contract_ids}`,
    cursor: next,
    order_by,
  });
};

export const getLoanCollectionsDetail = (contractAddress = testTokenAddress): Promise<IResponseCollection> => {
  return postQuicknode('', {
    id: 67,
    jsonrpc: '2.0',
    method: 'qn_fetchNFTCollectionDetails',
    params: [
      {
        page: 1,
        perPage: 80,
        contracts: [contractAddress],
      },
    ],
  });
};

export const getLoanCollections = (params: TLoanSortParams | {} | undefined): Promise<ILoanCollection[]> => {
  return getRequestBackend('/loan/collections/', {
    params,
  });
};

export const getLoanRequests = (config?: AxiosRequestConfig): Promise<ILoanRequest[]> => {
  return getRequestBackend('/loan/request', config);
};

export const getLoanRequestDetails = (id: string, config?: AxiosRequestConfig): Promise<ILoanRequestDetail> => {
  return getRequestBackend(`/loan/request/${id}`, config);
};

export const getActiveLoans = (config?: AxiosRequestConfig): Promise<TActiveLoans> => {
  return getRequestBackend('/loan/active-loans', config);
};

export const getActiveLoan = (id: string, config?: AxiosRequestConfig): Promise<IActiveLoan> => {
  return getRequestBackend(`/loan/active-loans/${id}`, config);
};

export const acceptLoanRequestByBackend = (body: IAcceptLoanRequestPayload): Promise<ILoanRequest[]> => {
  return postRequestBackend('/loan/accept-request', body);
};

export const deleteLoanRequest = (id: string) => {
  return deleteRequest(`/loan/request/${id}`);
};

export const updateLoanRequest = (id: string, body: ILoanTermBaseFields) => {
  return putRequest(`/loan/request/${id}`, body);
};

export const repayLoanByBackend = async (body: IPaybackLoanPayload) => {
  await postRequestBackend('/loan/repay', body);
};

export const forecloseLoanByBackend = async (body: IPaybackLoanPayload) => {
  await postRequestBackend('/loan/foreclose', body);
};

export const getLoanStatistics = async (): Promise<ILoanStatistics> => {
  return getRequestBackend('/loan/statistics');
};

export const getLentLoans = async (address: string): Promise<IMyLoan[]> => {
  return getRequestBackend('/loan/lent', {
    params: {
      address,
    },
  });
};

export const getBorrowedLoans = async (address: string): Promise<IMyLoan[]> => {
  return getRequestBackend('/loan/borrowed', {
    params: {
      address,
    },
  });
};

export const getOffersLoans = async (): Promise<ILoanRequest[]> => {
  return getRequestBackend('/loan/my-loan-offers', {
    params: {
      address: window.ethereum._state.accounts[0],
    },
  });
};

export const cancelLoanOffer = async (id: number) => {
  return deleteRequest(`/loan/offer/${id}`);
};

export const searchCollections = async (searchTerm: string): Promise<ICollectionSearch[]> => {
  return getRequestBackend('/loan/collections/', {
    params: {
      search: searchTerm,
    },
  });
};

export const getCollectionDetailsSimplehash = (collection_ids: string) => {
  return getSimpleHash<ICollectionSimplehashDetails>('collections/ids', {
    collection_ids,
  });
};

export const getLoanCollectionDetails = async (id: string): Promise<ICollectionLoanDetails> => {
  return getRequestBackend(`/loan/collections/${id}`);
};

export const updateLoanMemberAddress = async ({ id, address }: IPayloadUpdateLoanMember) => {
  return putRequest(`/loan/update-loan-member-address/${id}`, undefined, {
    params: {
      address,
    },
  });
};
