import { getRequest } from '@/api/api';
import { IWalletCryptoData } from '@/redux/wallet/model';
import { buildParams } from '@/utils/formatter';

import { INFTsByWallet, INFTsByWalletParams, IWalletCollectionParams, IWalletCollectionRes, IWalletDataParams, IWalletValuationRes } from './types/simpleHash';

export const getSimpleHash = async <T, P = Record<string, any>>(url: string, params: P & Record<string, any>): Promise<T> => {
  const options = {
    method: 'GET',
    headers: { accept: 'application/json', 'X-API-KEY': process.env.NEXT_PUBLIC_SIMPLE_HASH },
  };
  const queryParams = buildParams(params);

  try {
    const res = await fetch(`https://api.simplehash.com/api/v0/nfts/${url}?${queryParams}`, options);
    const nftData = await res.json();

    return nftData;
  } catch (e) {
    return e as T;
  }
};

export const getWalletTraitFloor = async (params: { wallet_address: string }): Promise<{ data: number }> => {
  const res = await getRequest('/simplehash/trait-floor', { params }, false, true);
  return res;
};

export const getWalletCryptoData = async (params: { wallet_addresses: string }): Promise<IWalletCryptoData> => {
  const res = await getRequest('/simplehash/wallet-crypto', { params }, false, true);
  return res;
};

export const getWalletValuation = async (params: IWalletDataParams): Promise<IWalletValuationRes> => {
  const res = await getRequest('/simplehash/wallet-valuation', { params }, false, true);
  return res;
};

export const getWalletCollection = async (params: IWalletCollectionParams): Promise<IWalletCollectionRes> => {
  const res = await getRequest('/simplehash/wallet-collection', { params }, false, true);
  return res;
};

export const getWalletNFTs = async (params: INFTsByWalletParams): Promise<INFTsByWallet> => {
  const res = await getRequest('/simplehash/wallet-nfts', { params }, false, true);
  return res;
};

export const getWalletAllNFTs = async (params: { wallet_addresses: string }): Promise<INFTsByWallet['nfts']> => {
  const res = await getRequest('/simplehash/wallet-allNfts', { params }, false, true);
  return res;
};

export const getAvailableChains = async () => {
  const res = await getRequest('/simplehash/chains', {}, false, true);
  return res;
};
