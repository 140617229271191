/* eslint-disable no-console */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { TopSalesState } from './model';
import { menuItems } from '../../constant';
import { getCollectionsByMarketPlace, getTopCollections } from '../../simplehash_api/collectionApi';
import { IByMarketPlaceParams, ITopCollectionParams, ITopCollectionsData } from '../../simplehash_api/types/simpleHash';

export const fetchCollectionsByPage = createAsyncThunk<ITopCollectionsData & { isLoadMore?: boolean }, ITopCollectionParams & { isLoadMore?: boolean }, { rejectValue: string }>('sales/fetchByPage', async (params, { rejectWithValue }) => {
  const { isLoadMore, ...restParam } = params;
  try {
    const response = await getTopCollections(restParam);
    return { ...response, isLoadMore };
  } catch (e) {
    return rejectWithValue((e as AxiosError).message);
  }
});

export const fetchByMarketPlace = createAsyncThunk('sales/fetchByMarketPlace', async (params: IByMarketPlaceParams, { rejectWithValue }) => {
  try {
    const response = await getCollectionsByMarketPlace(params);
    return response;
  } catch (e) {
    return rejectWithValue(e);
  }
});

const initialState: TopSalesState = {
  Collection: null,
  CollectionsAssets: null,
  CollectionTraits: null,
  NftTopSalesDetailFilter: {},
  selectedDay: menuItems[0].name,
  selectedDate: menuItems[0].date,
  loading: false,
  detailNftsLoading: false,
  error: null,
  singleCollectionLoading: false,
  CollectionAssetLoading: false,
  CollectionTraitLoading: false,
  NftTopSalesCollections: {
    collections: [],
    next: null,
    next_cursor: null,
    previous: null,
  } as ITopCollectionsData,
  marketPlaceLoading: false,
  NFTCollectionsMarketplace: [],
};

const topSalesSlice = createSlice({
  name: 'sales',
  initialState,
  reducers: {
    selectDay(state, action: PayloadAction<string[]>) {
      const [date, day] = action.payload;
      state.selectedDay = day;
      state.selectedDate = date;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCollectionsByPage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCollectionsByPage.fulfilled, (state, action) => {
        const { isLoadMore, collections, ...pageData } = action.payload;
        state.loading = false;
        state.NftTopSalesCollections = isLoadMore ? { collections: [...state.NftTopSalesCollections.collections, ...collections], ...pageData } : action.payload;
      })
      .addCase(fetchCollectionsByPage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })
      .addCase(fetchByMarketPlace.pending, (state) => {
        state.marketPlaceLoading = true;
      })
      .addCase(fetchByMarketPlace.fulfilled, (state, action) => {
        state.marketPlaceLoading = false;
        state.NFTCollectionsMarketplace = action.payload;
      });
  },
});

export const { selectDay } = topSalesSlice.actions;
export default topSalesSlice.reducer;
